<template>
  <div class="home" v-html="txt">

  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios'
import { hostus } from "../utils/func.js";

//const hostus = (process.env.NODE_ENV == "development" ? 'http://localhost:3000' : '')

export default {
  name: 'Home',
  components: {
    //HelloWorld
  },
  mounted: async function() {
    let res = await axios.get(`${hostus}/mysqlus/proto`, {params: {file: `infopubl-1` }})
    let proto = res.data
    if (proto.errmsg) this.txt = " Не найден файл" //this.$refs.text.innerHTML = '<el-empty description="Не найден протокол"></el-empty>' 
    else this.txt = proto
  },
  data() {
    return {
      txt: ''
    }
  },
}
</script>
